import React, {useState} from 'react';
import { QrReader } from 'react-qr-reader';
import ClipLoader from "react-spinners/ClipLoader";
import {
	useQuery,
	gql,
} from "@apollo/client";


const GUEST_QUERY = gql`
		     query getOrjan50Guest($path: String!)  {
			  getOrjan50Guest(fullpath: $path) {
				id
			    tableNumber
			  }
			}
		    `;


let guest;

function TableNumber({guid}) {

	const [displayQR, setDisplayQR] = useState(false);
	const [correctTable, setCorrectTable] = useState('');
	
	
	
	const { loading, error, data } = useQuery(GUEST_QUERY, {
		variables: { path: "/orjan50/guests/"+guid },
		onCompleted: data => {
		}
	});
	


	if (loading) {
		console.log('loading guest');
		return (
			<div>
				<ClipLoader loading={loading} size={150} />
			</div>
		);
	}
	if (error) {
		console.log('error loading guest');
		return `Error! ${error}`;
	}
	if (!data.getOrjan50Guest) {
		return (
			<div>
				Hittade ingen gäst med given nyckel.
			</div>
		);
	}
	
	


	
	if (!guest) {
		guest = data.getOrjan50Guest;
	}
	
	

	
	const check = (value) => {
    	setCorrectTable(value == guest.tableNumber);
	setDisplayQR(false);
	}
	

	
	const qrReader = () => {
		if (!displayQR) {
			return null;
		}
		return (
			<QrReader
			constraints={{ facingMode: 'environment' }}
        onResult={(result, error) => {
	

          if (result && result !== "" ) {
			check(result.text);
          }

          if (error) {
            console.info(error);
          }
        }}
        style={{ width: '100%' }}
      />
		)
	}
	
	const displayAnswer = () => {
		if (correctTable === '') {
			return null;
		}
		if (correctTable) {
			return (<span className="CorrectTable">Välkommen att slå dig ner.</span>);
		}
		else {
			return (<span className="WrongTable">Vänligen leta vidare.</span>);
		}
	}
	

	return (
		 <div>
			<br/>
			{displayAnswer()}
			<br/>
	         <button onClick={() => {setDisplayQR(!displayQR); setCorrectTable('')}}>{displayQR ? 'Stäng QR-läsaren' : 'Öppna QR-läsare'}</button>
	 
			{qrReader()}
			
	 

        </div>
	);
}

export default TableNumber;