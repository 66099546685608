import React, { useState, useEffect }  from 'react';

import {
	useLazyQuery,
	gql,
	useMutation
} from "@apollo/client";

import ClipLoader from "react-spinners/ClipLoader";
import {useLocation} from "react-router-dom";
import { Link } from "react-router-dom";

import BreadCrumbs from "../BreadCrumbs";

let queries = [];
let givenAnswers = {};
let lastQuery = 0;
let savedQueryNo = 1;

const QUERIES_QUERY = gql`
		     query getQuizQuery  {
			  getQuizQuery(fullpath: "/orjan50/quiz/quiz") {
				 properties {
			      ... on property_object {
			        name
			        type
			        object {
			          __typename
			          ... on object_QuizQuery {
			            id
			            queryText
			            option1
			            option2
			            option3
			            correctOption
						parent {
				          ... on object_Orjan50Guest {
				            id
				            name
				          }
				        }
			          }
			        }
			      }
				  ... on property_text {
				        name
						type
				        text
				  }
			    }				
			  }
			}
		    `;

const RESPONSE_QUERY = gql`
			query getQuizResponseListing($filter: String!)  {
			  getQuizResponseListing(filter: $filter) {
			    edges {
			      node {
				  id
			      selectedOption
			      isCorrect
				  query {
			        ... on object_QuizQuery {
			          id
			        }
			      }
				 }
			   }
			  }
			}
		    `;

const CREATE_QUERY_RESPONSE_MUTATION = gql`
	 mutation createQuizResponse($key: String!, $path: String!, $queryId: Int!, $selectedOption: Float!) {
	  createQuizResponse(key: $key, published: true, path: $path, 
      input: {selectedOption: $selectedOption, query: {id: $queryId, type: "object"}}) {
	    success
	    message
	    output {
	      id
	      selectedOption
	      isCorrect
		  query {
	        ... on object_QuizQuery {
	          id
	        }
	      }
    	}
	}
   }
  `;


function Quiz({guid}) {
	const search = useLocation().search;
	const q = new URLSearchParams(search).get('q');
	//const [lastQuery, setLastQuery] = useState(0);
	const [queryNo, setQueryNo] = useState(q || savedQueryNo);
	const [selectedOption, setSelectedOption] = useState(0);
	//const [givenAnswers , setGivenAnswers] = useState({});
	const [changed, setChanged] = useState(0);
	let displayedSelectedOption = selectedOption;
	
	const path = "/orjan50/guests/"+guid;
	//const responseKey = 'r-'+guid
		
	const [callCreateResponse, { loading: createLoading, error: createError }] = useMutation(CREATE_QUERY_RESPONSE_MUTATION, { 
		variables: { path: path }, 
		onCompleted: data => {	
			const response = data.createQuizResponse.output;
			givenAnswers[response.query.id] = {isCorrect: response.isCorrect, selectedOption: response.selectedOption};
			//setGivenAnswers(givenAnswers);
			setSelectedOption(0);
		},
		onError: data => {
			console.log('Creating response failed');			
		}
	});
	
	
	
	
	
	const [getResponses, { loading: responseLoading, error: responseError, data: answerData, refetch: refetchResponses }] = useLazyQuery(RESPONSE_QUERY, {
		variables: {filter: '{"o_path": {"$like" : "/orjan50/guests/'+guid+'/%"}}'},
		onCompleted: data => {
			givenAnswers = {};
			data.getQuizResponseListing.edges.forEach(el => {
				givenAnswers[el.node.query.id] = {isCorrect: el.node.isCorrect, selectedOption: el.node.selectedOption}
				});
			//setGivenAnswers(answers);
			if (!q) {
				for (let query of queries) {
				  	if (!givenAnswers.hasOwnProperty(query.id)) {
					console.log("found next:"+query.key);
					    savedQueryNo = parseInt(query.key);
						setQueryNo(savedQueryNo);
						break;
					}
				}
			}
			setChanged(new Date());
		}
	});
	
	const [getQueries, { loading, error, data, refetch }] = useLazyQuery(QUERIES_QUERY, {
		variables: {},
		onCompleted: data => {
			const lastQ = data.getQuizQuery.properties.find(p => p.name === 'lastQuery');
			lastQuery = parseInt(lastQ.text); //setLastQuery(parseInt(lastQ.text));
			refetchResponses();
		}
	});
	
	useEffect(() => {
		if (!loading) {
			refetch();
			
		}

	}, []);
	
	if (loading) {
		console.log('loading queries');
		return (
			<div>
				<ClipLoader loading={loading} size={150} />
			</div>
		);
	}
	if (createLoading) {
		console.log('loading response');
		return (
			<div>
				<ClipLoader loading={createLoading} size={150} />
			</div>
		);
	}
	if (error) {
		console.log('error loading guest');
		return `Error! ${error}`;
	}
	if (createError) {
		console.log('error loading guest');
		return `Error! ${createError}`;
	}
	
	if (data) {
		queries = data.getQuizQuery.properties.filter(prop => prop.type==='object' && parseInt(prop.name) <= lastQuery).map(prop => {return {id: prop.object.id, key: prop.name, queryText: prop.object.queryText, option1: prop.object.option1, 
			option2: prop.object.option2, option3: prop.object.option3, correctOption: prop.object.correctOption,  questioner: prop.object.parent.name};});
		queries.sort((a, b) => {return parseInt(a.key) - parseInt(b.key)});
		//queries.forEach(q => {if(parseInt(q.key) >= nextQueryKey) nextQueryKey++;})
		
		
	}
	const query = queries.find(q => parseInt(q.key) == queryNo);
	displayedSelectedOption = query && givenAnswers.hasOwnProperty(query.id) ? givenAnswers[query.id].selectedOption : selectedOption;
	const goLeft = () => {
		if (queryNo <= 1) {
			return null;
		}
		const q = parseInt(queryNo) - 1;
		return (
			<><Link to={"?q="+q} onClick={() => {setQueryNo(q);savedQueryNo = q;setSelectedOption(0)}}>&lt;&lt;</Link>&nbsp;</>
		);
	}; 
	
	const goRight = (text = '>>') => {
		if (queryNo >= lastQuery) {
			return null;
		}
		const q = parseInt(queryNo) + 1;
		return (
			<><Link to={"?q="+q} onClick={() => {setQueryNo(q);savedQueryNo = q;setSelectedOption(0)}}>{text}</Link>&nbsp;</>
		);
	}; 
	
	const paging = () => {
		return (
			
			Array.from(Array(lastQuery)).map((e,i)=> {
				const  q = i+1;
				return (
					<><Link key={q} to={"?q="+q} onClick={() => {setQueryNo(q);savedQueryNo = q;setSelectedOption(0)}}><span className={q==queryNo ? "selectedpage" : "unselectedpage"}>{q}</span></Link>&nbsp;</>
				);
			}
		));
	};
	
	
	const sendAnswer = () => {
		if (selectedOption < 1 || selectedOption > 3) {
			alert('Du måste markera ett svar.');
			return;
		}
		const key = 'r-' + query.id;
		callCreateResponse({ variables : { key: key, queryId: parseInt(query.id), selectedOption: selectedOption }});
		
	}
	
	const answer = () => {
		if (!query) {
			return null;
		}
		if (!givenAnswers.hasOwnProperty(query.id)) {
			return (<button onClick={() => {sendAnswer()}}>Lås in svar</button>);
		}
		return (<div>Du hade {givenAnswers[query.id].isCorrect ? 'rätt 😀' : 'fel 🙁'}. Rätt svar: {query.correctOption === 1 ? '1' : query.correctOption === 2 ? 'X' : '2'} </div>); 
	}
	
	const showCorrect = (no) => {
		if (!givenAnswers.hasOwnProperty(query.id)) {
			return null;
		}
		return (<>{query.correctOption === no ? '💚' :''} </>); 
	}
	
	const showQuery = (no) => {
		if (!query) {
			return null;
		}
		return (
			<>Fråga {queryNo}
		<h3 className="quizquery">{query.queryText}</h3>
		<h4 className="quizqueryoptionnumber">1 {showCorrect(1)}</h4>
		<span onClick={() => {setSelectedOption(1)}} className={displayedSelectedOption === 1 ? 'quizqueryoptionselected' : 'quizqueryoption' }>{query.option1}</span>
		
		<h4 className="quizqueryoptionnumber">X {showCorrect(2)}</h4>
		<span onClick={() => {setSelectedOption(2)}} className={displayedSelectedOption === 2 ? 'quizqueryoptionselected' : 'quizqueryoption' }>{query.option2}</span>
		
		<h4 className="quizqueryoptionnumber">2 {showCorrect(3)}</h4>
		<span onClick={() => {setSelectedOption(3)}} className={displayedSelectedOption === 3 ? 'quizqueryoptionselected' : 'quizqueryoption' }>{query.option3}</span>
		<br/>
		<div className="queryQuestioner">© {query.questioner}</div>
		 </>
	); 
	}
	console.log(queryNo+' '+savedQueryNo);

	return (
		<div className="quizframe">
		<BreadCrumbs path={[{link: "/", title:"Hem"}]} title="Quiz" />
		<button onClick={() => {refetch()}}>↻</button><br/>
		{goLeft()}
		{paging() }
		{goRight()}
	<br/>
		{showQuery()}

        <p>
<hr/>
		{answer()}
		</p>
		{goRight('Nästa')}
		</div>
	);
	
}

export default Quiz;