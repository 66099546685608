import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

function Invite ({setIsSignedIn, setPwd, setServer}) {
	
	 useEffect(() => {
           const interval = setInterval(() => window.location.href='/', 300);
		   return () => clearInterval(interval);
    }, []);

	
	let [searchParams, setSearchParams] = useSearchParams();
	
	const apiKey = searchParams.get('apiKey');
	if (apiKey) {
		setIsSignedIn(apiKey);
	}
	
	const guid = searchParams.get('guid');
	if (guid) {
		setPwd(guid);
	}
	
	const server = searchParams.get('server');
	if (server) {
		setServer(server);
	}
	else {
		setServer('https://www.glavichsandell.se/');
	}
	
    return (
        <div className="Invite">
           Välkommen!
        </div>
    );
}

export default Invite;