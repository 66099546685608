import React, {useState}   from 'react';

import {
	useQuery,
	gql,
} from "@apollo/client";

import ClipLoader from "react-spinners/ClipLoader";
import { Link } from "react-router-dom";
import BreadCrumbs from "../BreadCrumbs";

function Guest({server}) {

    let guest;
	const [guestId, setGuestId] = useState(parseInt(window.location.href.split("/").pop()));
	const [showLargeImage, setShowLargeImage] = useState('');
	
	
	const GUEST_QUERY = gql`
		     query getOrjan50Guest($id: Int!)  {
			  getOrjan50Guest(id: $id) {
				id
			    name
				portrait {
					fullpath
					id
				}
				attending
				description
				publicGreeting
				extraGuest1
				extraGuest2
				extraGuest3
				extraGuest4
				extraGuest1Portrait {
					fullpath
					id
				}
				extraGuest2Portrait {
					fullpath
					id
				}
				extraGuest3Portrait {
					fullpath
					id
				}
				extraGuest4Portrait {
					fullpath
					id
				}
				inPairWith {
					... on object_Orjan50Guest {
			            id
			            name
						attending       
			          }
				}
			  }
			}
		    `;

	

	const { loading, error, data } = useQuery(GUEST_QUERY, {
		variables: { id: guestId},
		onCompleted: data => {
		}
	});
	


	if (loading) {
		console.log('loading guest');
		return (
			<div>
				<ClipLoader loading={loading} size={150} />
			</div>
		);
	}
	if (error) {
		console.log('error loading guest');
		return `Error! ${error}`;
	}
	if (!data.getOrjan50Guest) {
		return (
			<div>
				Hittade ingen gäst med given nyckel.
			</div>
		);
	}
	
	


	
	if (!guest) {
		guest = data.getOrjan50Guest;
	}
	
	if (!guest.attending) {
		return null;
	}
	
	const inPairWith = () => {
		if (!guest.inPairWith || !guest.inPairWith.attending) {
			return null;
		}
		return (<>I par med <Link onClick={() => {setGuestId(parseInt(guest.inPairWith.id))}} to={guest.inPairWith.id}>{guest.inPairWith.name}</Link><br/></>);
	}
	
	const extraGuest = (no) => {
		const name = guest['extraGuest'+no];
		if (!name) {
			return null;
		}
		return (
			<>
				<h1>{name}</h1>
			    {portrait('extraGuest'+no+'Portrait')}
			</>							
		);
	}
	
	const portrait = (attribute) => {
		if (!guest[attribute]) {
			return null;
		}
		const path = guest[attribute].fullpath;
		const imgpath = path.substring(1, path.lastIndexOf('/')+1) + 'image-thumb__'+guest[attribute].id+'__orjan50guestthumb' + path.substring(path.lastIndexOf('/'));
		return (<><br/><img className="guestimage" onClick={() => setShowLargeImage(attribute)} height="250" src={server + imgpath} /><br/></>);
	}
	
	const largeImage = () => {
		if (!showLargeImage) {
			return null;
		}
		return (<div className="largeimage" onClick={() => {setShowLargeImage('')}}>
		<span className="closelargeimage" onClick={() => {setShowLargeImage('')}}> &times;</span><br/>
		<img  src={server + guest[showLargeImage].fullpath} /></div>);
	}

	return (
		
	    <>
			<BreadCrumbs path={[{link: "/", title:"Hem"}, {link: "/guestlist", title:"Gästlista"}]} title={guest.name} />
			<div className="guest" >
			   {largeImage()}
				<h1>{guest.name}</h1>
				{inPairWith()}
				{portrait('portrait')} 
				{extraGuest(1)}{extraGuest(2)}{extraGuest(3)}{extraGuest(4)}
				<br/>
				
				<div>
			   
					<h2>Gästens beskrivning om sig själv</h2>
	
					
					{guest.description}
	
					
					<h2>Hälsning till jubilaren</h2>
	
					
					{guest.publicGreeting}
	
					
	
			   </div>
			</div>
		</>
	);
}

export default Guest;