import React, { useState, useEffect } from 'react';

import {
	useLazyQuery,
	gql
} from "@apollo/client";

import ClipLoader from "react-spinners/ClipLoader";

let itemContainer = {};
let refetchFunction;
	

function refechItems() {
	if (refetchFunction)
		refetchFunction();
}


function ItemList({ queryName, folder, keys, sortBy, setLoading, loaderSize=0, renderFunction }) {

	let fields = '';

	Object.entries(keys).forEach(entry => {
		const [key, value] = entry;
		fields += ' ' + key;
		if (value) {
			fields += '{'+value+'}';
		}
	});

	const ITEMS_QUERY = gql`
		     query listing($filter: String!)  {
			  listing: ${queryName}(filter: $filter) {
			    edges {
			      node {
			        id
					modificationDate
					${fields}
			      }
			    }
			  }
			}
`;

	const [modefiedSince, setModefiedSince] = useState(0);
	const filter = '{"o_path": {"$like" : "'+folder+'/%"}, "o_modificationDate": {"$gt" :"' + modefiedSince + '"}}';
	const [getItems, { loading, error, data, refetch }] = useLazyQuery(ITEMS_QUERY, {
		variables: { filter: filter },
		onCompleted: data => {
			const fetchedItems = data.listing.edges.map(query => {
				let obj = {id: parseInt(query.node.id), modificationDate: query.node.modificationDate};
				Object.entries(keys).forEach(entry => {
					const [key, value] = entry;
					obj[key] = query.node[key];
				});
				return obj;
			});

			let items = itemContainer[queryName+folder] || [];
			const newItems = [];
			items.forEach(item => {
				if (!fetchedItems.find(i => { return i.id === item.id })) {
					newItems.push(item);
				}
			});
			newItems.push(...fetchedItems);

			items = newItems.sort((a, b) => {
				if (sortBy) {
					return a[sortBy].localeCompare(b[sortBy]);
				}
				return a.id - b.id;
			}, items);



			let modSince = modefiedSince;
			items.forEach(item => {
				modSince = item.modificationDate > modSince ? item.modificationDate : modSince;
			});
			setModefiedSince(modSince);
			itemContainer[queryName+folder] = items;
		}
	});
	refetchFunction = () => {
		refetch();
	};
	
	
	useEffect(() => {
		if (!loading) {
			refetch();
		}

	}, []);


	if (error) {
		console.log('error loading wishlist');
		return false;
	}
	
	const whileLoading = () => {
		if (!loaderSize) {
			return null;
		}
		return (
			<div className="spinnerpopup">
				<ClipLoader className="popuptext" loading={loading} size={loaderSize} />
			</div>
		);
	}	
	let items = itemContainer[queryName+folder] || [];
	
	if (renderFunction) {
		return renderFunction(items, whileLoading);
	}


	return (
		<>
			<span className="refreshwishlist" onClick={() => refetch()}>🔄</span>
			  {whileLoading()}
				{items.map(item => (<div key={item.id} className="wishlistitem" >  
												<h2 className="itemListName">{item.id}</h2> 
												{item.modificationDate}
												
									</div>											
									)

				)}
		</>
	);
}

export default ItemList;
export {refechItems};
