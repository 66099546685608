import React  from 'react';



function ListImage({server, image, thumbnail, height, onClick}) {

	const path = image ? image.fullpath : null;
	const id = image ? image.id : 0;

	const thumbPath = path && thumbnail ? path.substring(0, path.lastIndexOf('/')+1) + 'image-thumb__'+id+'__' + thumbnail + path.substring(path.lastIndexOf('/')) : false;
	
	
	if (!path) {
			return null;
	}
	
	const metadata = (name, label = '') => {
		if (!image.metadata) {
			return null;
		}
		const meta = image.metadata.find(m => m.name === name);
		if (!meta) {
			return null;
		}
		return (
			<span className={"imagemeta"+name}>{label}{meta.data}</span>
		);
	}
	
	return (
		<>		   
		     {metadata('title')} {metadata('copyright', '©')}<br/>
			<img className="guestlistimage" height={height || 50} src={server.replace(/\/+$/, '') + (thumbPath ? thumbPath : path)} onClick={() => {if(onClick) onClick()}}/>
			<br/>
			{metadata('description')}
		</>
	);
}

export default ListImage;