import React   from 'react';

import {
	useQuery,
	gql
} from "@apollo/client";

import ClipLoader from "react-spinners/ClipLoader";
import { Link } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import BreadCrumbs from "../BreadCrumbs";

let nextQueryKey = 1;

function QuizQueries({ guid }) {

	//const [saving, setSaving] = useState(false);
	//const [attending, setAttending] = useState();
	
	let queries = [];
	const path = "/orjan50/guests/"+guid;
	
	const [searchParams] = useSearchParams();
	const addedKey = searchParams.get('added');

	const QUERIES_QUERY = gql`
		     query getQuizQueryListing($filter: String!)  {
			  getQuizQueryListing(filter: $filter) {
			    edges {
			      node {
			        id
					key
			        queryText
			        option1
			        option2
					option3
					correctOption
			      }
			    }
			  }
			}
`;

	const filter  = '{"o_path": {"$like" : "'+path+'/%"}}';
	const { loading, error, data, refetch } = useQuery(QUERIES_QUERY, {
		variables: { filter:  filter},
		onCompleted: data => {

		}
	});
	


	if (loading) {
		console.log('loading queries');
		return (
			<div>
				<ClipLoader loading={loading} size={150} />
			</div>
		);
	}
	if (error) {
		console.log('error loading guest');
		return `Error! ${error}`;
	}
	if (data) {
		queries = data.getQuizQueryListing.edges.map(query => {return {id: query.node.id, key: query.node.key, queryText: query.node.queryText, option1: query.node.option1, 
			option2: query.node.option2, option3: query.node.option3, correctOption: query.node.correctOption };});
		queries.forEach(q => {if(parseInt(q.key) >= nextQueryKey) nextQueryKey++;})
		
		queries.sort((a,b) => parseInt(a.key) - parseInt(b.key));
	}
	
	if (addedKey && parseInt(addedKey) >=  nextQueryKey) {
		refetch();
	}
	
	const breadcrumbs = () => {
		if (window.location.pathname.includes('mypage')) {
			return null;
		}
		return (
			<BreadCrumbs path={[{link: "/", title:"Hem"}, {link: "/mypage", title:"Min sida"}]} title="Quiz-lista" />					
		);
	}
	
	const queryLength = 30;
	const source = window.location.pathname;
	return (
		<>
		{breadcrumbs()}
		<h2>Mitt bidrag till Quizen</h2>
		En quiz vill vi väl alla ha. Här är din chans med att bidra med frågor. Frågorna kan handla om vad som helst, men någon fråga får gärna
		ha koppling till jubilaren.
		<table className="quizlist">
			<tbody>
				{queries.map(query => ( <tr className="resultdisplayrow" key={query.id}>  
												<td align="right">{query.key}</td>
												<td >{query.queryText.length <= queryLength ? query.queryText : query.queryText.substring(0, queryLength) + '...'}</td>
												<td><Link to={"/editquery?query="+query.key}>🖊</Link></td>												
											</tr>))}
			</tbody>
		</table>
		<Link to={"/newquery?query="+nextQueryKey+'&source='+source}>Lägg till fråga</Link>
		</>
	);

}

export default QuizQueries;
