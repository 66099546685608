import React, { useState }  from 'react';

import {
	useQuery,
	gql,
	useMutation
} from "@apollo/client";

import ClipLoader from "react-spinners/ClipLoader";

import MyPageTexts from "./MyPage/MyPageTexts";
import Portrait from "./MyPage/Portrait";
import QuizQueries from "./Quiz/QuizQueries"
import ImageGallery from './ImageGallery/ImageGallery';
import moment from 'moment';
import BreadCrumbs from "./BreadCrumbs";


let guestId;
let guest;

function MyPage({ guid, server }) {

	const [saving, setSaving] = useState(false);
	const [attending, setAttending] = useState();
	const [version, setVersion] = useState(moment());


	const GUEST_QUERY = gql`
		     query getOrjan50Guest($path: String!)  {
			  getOrjan50Guest(fullpath: $path) {
				id
			    name
				email
				attending
				portrait {
					fullpath
					id
				}
				description
				privateGreeting
				publicGreeting
				message
				extraGuest1
				extraGuest2
				extraGuest3
				extraGuest4
				extraGuest1Portrait {
					fullpath
					id
				}
				extraGuest2Portrait {
					fullpath
					id
				}
				extraGuest3Portrait {
					fullpath
					id
				}
				extraGuest4Portrait {
					fullpath
					id
				}
			  }
			}
		    `;

	const UPDATE_GUEST_MUTATION = gql`
	  mutation updateOrjan50Guest($id: Int!, $email: String!, $attending: Boolean, $description: String!, $privateGreeting: String!, $publicGreeting: String!, $message: String!,
								  $extraGuest1: String!, $extraGuest2: String!, $extraGuest3: String!, $extraGuest4: String!) {
	  	updateOrjan50Guest(id: $id, input: {email: $email, attending: $attending, description: $description, privateGreeting: $privateGreeting, publicGreeting: $publicGreeting, message: $message,
											extraGuest1: $extraGuest1, extraGuest2: $extraGuest2, extraGuest3: $extraGuest3, extraGuest4: $extraGuest4 }) {
		  success
	      message 
		  output {
			id
			name
				email
				attending
				portrait {
					fullpath
					id
				}
				description
				privateGreeting
				publicGreeting
				message
				extraGuest1
				extraGuest2
				extraGuest3
				extraGuest4
				extraGuest1Portrait {
					fullpath
					id
				}
				extraGuest2Portrait {
					fullpath
					id
				}
				extraGuest3Portrait {
					fullpath
					id
				}
				extraGuest4Portrait {
					fullpath
					id
				}
		  }
		}
	 }
	`;
	//const { loading, error, data } = queryRunnerGQL(GUEST_QUERY); //{loading: false, error: false, data: {getKanallopetRunner: {name: "kalle"}}}; //useQuery(GUEST_QUERY);

	const { loading, error, data, refetch: refetchGuest } = useQuery(GUEST_QUERY, {
		variables: { path: "/orjan50/guests/"+guid },
		onCompleted: data => {
			guest = data.getOrjan50Guest;
			if (guest) {
				setAttending(guest.attending);
				setVersion(moment());
				guestId = parseInt(guest.id);
			}
		}
	});
	
	const extraName = (guest, no) => {
		if (!guest['extraGuest'+no]) {
			return '';
		}
		return  ', '+ guest['extraGuest'+no];
	}
	
	const [callUpdateGuest, { error: gError }] = useMutation(UPDATE_GUEST_MUTATION, { 
		variables: { }, 
		onCompleted: data => {
			guest = data.updateOrjan50Guest.output;	
			const value = guest.name + extraName(guest, 1) + extraName(guest, 2) + extraName(guest, 3) + extraName(guest, 4);
			localStorage.setItem('guest_name', value);
			setSaving(false);
		},
		onError: data => {
			console.log('Updating reading failed');			
		}
	});


	if (loading) {
		console.log('loading guest');
		return (
			<div>
				<ClipLoader loading={loading} size={150} />
			</div>
		);
	}
	if (error) {
		console.log('error loading guest');
		return `Error! ${error}`;
	}
	if (gError) {
		console.log('error updating guest');
		return `Error! ${gError}`;
	}
	if (!data.getOrjan50Guest) {
		return (
			<div>
				Hittade ingen gäst med given nyckel.
			</div>
		);
	}
	
	const getFormElementValue = (form, name) => {
		for (let i=0; i<form.length; i++) {
			if (form[i].name === name) {
				return form[i].value;
			}
		}
		return null;
	}
	
	
	const handleAttendingChange = event => {
	    console.log(event.target.value);
	    setAttending(event.target.value === 'yes');
	  };

	const onSave = (event) => {
		setSaving(true);
		const attending = !event.target.form[0].checked && !event.target.form[1].checked ? null : event.target.form[0].checked;
		const email = getFormElementValue(event.target.form, 'email'); //event.target.form[2].value;
		const description = getFormElementValue(event.target.form, 'description');
		const publicGreeting = getFormElementValue(event.target.form, 'publicGreeting');
		const privateGreeting = getFormElementValue(event.target.form, 'privateGreeting');
		const message = getFormElementValue(event.target.form, 'message');
		const extraGuest1 =getFormElementValue(event.target.form, 'extraGuest1');
		const extraGuest2 = getFormElementValue(event.target.form, 'extraGuest2');
		const extraGuest3 = getFormElementValue(event.target.form, 'extraGuest3');
		const extraGuest4 = getFormElementValue(event.target.form, 'extraGuest4');
		callUpdateGuest({ variables : { id: guestId, email: email, attending: attending, description: description, 
										publicGreeting: publicGreeting, privateGreeting: privateGreeting, message: message,
										extraGuest1: extraGuest1, extraGuest2: extraGuest2, extraGuest3: extraGuest3, extraGuest4: extraGuest4}});
	}
	
	if (saving) {
		return (
			<ClipLoader loading={saving} size={150} />
		);
	}
	
	if (!guest) {
		guest = data.getOrjan50Guest;
	}
	
	
	const extraPortrait = (name, no, portrait) => {		
		if (!name) {
			return null;
		}
		const path  = portrait ? portrait.fullpath : null;
		const id =  portrait ? portrait.id : 0;
		return (<><Portrait guid={guid} attributeName={"extraGuest"+no+"Portrait"} path={path} server={server} refetchGuest={refetchGuest} id={id} thumbnail="orjan50mythumb"/><br/></>);
	}

	return (
		

		<div className="mypage">
		   <BreadCrumbs path={[{link: "/", title:"Hem"}]} title="Min sida" />
			<strong>{guest.name}</strong>
			<Portrait guid={guid} attributeName="portrait" id={guest.portrait ? guest.portrait.id : null} thumbnail="orjan50mythumb" path={guest.portrait ? guest.portrait.fullpath : null} server={server} refetchGuest={refetchGuest} /> 
			<form>
				<table>
					<tbody>
						<tr><td>Osa:</td><td>
							<input id="attendingyes" type="radio" value="yes" name="attending" checked={attending === true}   onChange={handleAttendingChange}/> <label htmlFor="attendingyes">Kommer</label>
			        		<input id="attendingno" type="radio" value="no" name="attending"  checked={attending === false}   onChange={handleAttendingChange}/> <label htmlFor="attendingno">Kommer ej</label>
						</td></tr>
						<tr><td>E-post:</td><td><input type="email" name="email" defaultValue={guest.email}/></td></tr>					
					</tbody>
				</table>
				
				
				<p>
				 Namnge den partner och/eller de barn du tar med dig som inte fått en egen inbjudan:<br/>
					1. <input type="text" name="extraGuest1" defaultValue={guest.extraGuest1}/><br/>
					{extraPortrait(guest.extraGuest1, 1, guest.extraGuest1Portrait)}
					2. <input type="text" name="extraGuest2" defaultValue={guest.extraGuest2}/><br/>
					{extraPortrait(guest.extraGuest2, 2, guest.extraGuest2Portrait)}
					3. <input type="text" name="extraGuest3" defaultValue={guest.extraGuest3}/><br/>
					{extraPortrait(guest.extraGuest3, 3, guest.extraGuest3Portrait)}
					4. <input type="text" name="extraGuest4" defaultValue={guest.extraGuest4}/><br/>
					{extraPortrait(guest.extraGuest4, 4, guest.extraGuest4Portrait)}
				</p>
				<MyPageTexts guest={guest}/>
				<button type="button" name="saveGuest" onClick={onSave}>Spara</button>
				<ClipLoader loading={saving} size={150} />
			</form>
			<QuizQueries guid={guid}/>
			<ImageGallery guid={guid} server={server}/>
		</div>
	);
}

export default MyPage;