import React, {useState, useEffect}   from 'react';

import {
	useLazyQuery,
	gql
} from "@apollo/client";

import ClipLoader from "react-spinners/ClipLoader";
import ListImage from '../GuestList/ListImage';
import { Link } from "react-router-dom";
import {useLocation} from "react-router-dom";
import BreadCrumbs from "../BreadCrumbs";

const PAGE_SIZE  = 20;

function PagedList({ server }) {

	const search = useLocation().search;
	const p = new URLSearchParams(search).get('page');
	
	
	const [page, setPage] = useState(p ? parseInt(p) : 1);
	let start = new URLSearchParams(search).get('start') || (page - 1) * PAGE_SIZE;;
	
	const [showLargeImage, setShowLargeImage] = useState('');
	
	let images = [];

	const GALLERY_QUERY = gql`
		     query getAssetFolder  {
				  getAssetFolder(fullpath: "/orjan50/gallery") {
				  	children {
				      ...on asset  {
				        fullpath
				        id
						metadata {
				          name
				          data
				        }
				      }
				    }
				  }
			}
`;

	
	const [getImages, { loading, error, data, refetch }] = useLazyQuery(GALLERY_QUERY, {
		variables: { },
		onCompleted: data => {

		}
	});
	
	useEffect(() => {
		console.log('enter GuestList');
		if (!loading) {
			console.log('get GuestList');
			 refetch();
		}
	   
	}, []);
	
	const largeImage = () => {
		if (!showLargeImage) {
			return null;
		}
		return (<div className="largeimage" onClick={() => {setShowLargeImage('')}}>
		<span className="closelargeimage" onClick={() => {setShowLargeImage('')}}> &times;</span><br/>
		<img  src={server + showLargeImage.fullpath} /></div>);
	}
	


	if (loading) {
		console.log('loading images');
		return (
			<div>
				<ClipLoader loading={loading} size={150} />
			</div>
		);
	}
	if (error) {
		console.log('error loading guest');
		return `Error! ${error}`;
	}
	if (data) {
		const gallery = data.getAssetFolder.children || [];
		images = gallery.map(image => {return {id: image.id, fullpath: image.fullpath, metadata: image.metadata };});
	}
	
	
	const end = page * PAGE_SIZE;
	const lastPage = Math.trunc(images.length / PAGE_SIZE) + (images.length % PAGE_SIZE  === 0 ? 0 : 1);
	
	if (page > lastPage && lastPage > 0) {
		setPage(lastPage);
	}
	
	const shownImages = images.slice(start, end);
	
	const leftPage = () => {
		if (page <= 1) {
			return null;
		}
		const p = page - 1;
		const start = (p - 1) * PAGE_SIZE;
		return (
			<><Link to={"?page="+p+"&start="+start} onClick={() => {setPage(p)}}>&lt;&lt;</Link>&nbsp;</>
		);
	}; 
	
	const rightPage = () => {
		if (page >= lastPage) {
			return null;
		}
		const p = page + 1;
		const start = (p - 1) * PAGE_SIZE;
	
		return (
			<><Link to={"?page="+p+"&start="+start} onClick={() => {setPage(p)}}>&gt;&gt;</Link>&nbsp;</>
		);
	}; 
	
	const paging = () => {
		return (
			
			Array.from(Array(lastPage)).map((e,i)=> {
				const  p = i+1;
				const start = (p - 1) * PAGE_SIZE;
				return (
					<><Link key={p} to={"?page="+p+"&start="+start} onClick={() => {setPage(p)}}><span className={p==page ? "selectedpage" : "unselectedpage"}>{p}</span></Link>&nbsp;</>
				);
			}
		));
	};
	
	const loadMore = () => {
		if (page >= lastPage) {
			return null;
		}
		const p = page + 1;
		return (
			<><Link to={"?page="+p+"&start="+start} onClick={() => {setPage(p)}}>Visa fler</Link>&nbsp;</>
		);
	}; 
	

	
	
	return (
		<div className="gallery">
		<BreadCrumbs path={[{link: "/", title:"Hem"}]} title="Bildgalleri" />
		{largeImage()}
		{leftPage()}
		{paging() }
		{rightPage()}
		<div class="gallerylist">
			{shownImages.map((image, i) => (<div key={image.id} className="galleryimage">
				<ListImage image={image} thumbnail="orjan50guestthumb" height="250" server={server} onClick={() => setShowLargeImage(image)}/>
			</div>))}
			</div>

		<br/>
		{loadMore()}
		<br/>
		</div>
	);

}

export default PagedList;
