import React, { useState }  from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileImage } from '@fortawesome/free-regular-svg-icons'
import FileUpload from '../FileUpload';


function Portrait({ guid, attributeName, path, server, refetchGuest, id, thumbnail }) {
	
	const [imagesuffix, setImagesuffix] = useState(moment());
	const [showImageForm, setShowImageForm] = useState(false);
	
	const thumbPath = path && thumbnail ? path.substring(0, path.lastIndexOf('/')+1) + 'image-thumb__'+id+'__' + thumbnail + path.substring(path.lastIndexOf('/')) : false;
	
	const portrait = () => {
		if (!path) {
			return (<FontAwesomeIcon className="nobreak" icon={faFileImage} />);
		}
		return (<img height="50" src={server.replace(/\/+$/, '') + (thumbPath ? thumbPath : path) + '?v='+imagesuffix} />);
	}
	
	
	
	const changeImage = () => {
		setShowImageForm(true);
	}
	
	const fileUploaded = () => {
		if (path) {
			setImagesuffix(moment());
		}
		else {
			refetchGuest();
		}
	}
	
	const imageForm = () => {
		if (!showImageForm) {
			return null;
		}
		return (
			<div className="modal">
				<div className="modal_content">
					<span className="close" onClick={() => {setShowImageForm(false)}}> &times;</span><br/>
					<FileUpload  guid={guid} server={server} type={attributeName} fileUploaded={fileUploaded}/>
				</div>
			</div>);
	}
	

	return (
		
		<div>
		{portrait()} <button type="button" onClick={changeImage}>⬆{(path ? 'Uppdatera' : 'Ladda upp') + ' porträttbild'}</button> {imageForm()}
				
		</div>
	);
}

export default Portrait;