import React from 'react';

import ItemList, {refechItems} from '../ItemList';

import BreadCrumbs from "../BreadCrumbs";


function WishList() {
	
	const description = (item) => {
		if (!item.description) {
			return null;
		}
		return (
			<>
				{item.description}<br/>
			</>
		);
	}	
	
	const link = (item) => {
		if (!item.link) {
			return null;
		}
		return (
			<a href={item.link.path} target="_blank">{item.link.text}</a>
		);
	}
	
	
	const keys = {key: "", 
				  name: "",
				  description: "",
				  link: "text path"
				}
	
	const render = (items, whileLoading) => {
		return (
		  <>
			<BreadCrumbs path={[{link: "/", title:"Hem"}]} title="Presentförslag" /> <span className="refreshwishlist" onClick={() => refechItems()}>🔄</span>
	          {whileLoading()}
              {items.map(item => (<div  className="wishlistitem" >  
												<h2 className="itemListName">{item.name}</h2> 
												{description(item)}
												{link(item)}
									</div>											
									)

				)}
		  </>
	  );
	}	
	
	return (
		<>
	         <ItemList  queryName="getWishListItemListing"  folder="/orjan50/wishlist"  keys={keys} sortBy="key"  loaderSize="50px" renderFunction={render}/>
              
		</>
	);
}

export default WishList;
	