import React, { useState }  from 'react';

import {
	useQuery,
	gql,
	useMutation
} from "@apollo/client";

import ClipLoader from "react-spinners/ClipLoader";
import { useSearchParams } from "react-router-dom";

import QuizQueryForm from './QuizQueryForm';
import BreadCrumbs from "../BreadCrumbs";

let queryId;
let query;

function QuizQuery({ guid }) {

	const [saving, setSaving] = useState(false);

	const QUERY_QUERY = gql`
		     query getQuizQuery($path: String!)  {
			  getQuizQuery(fullpath: $path) {
				 id
					key
			        queryText
			        option1
			        option2
					option3
					correctOption
			  }
			}
		    `;

	const UPDATE_QUERY_MUTATION = gql`
	  mutation updateQuizQuery($id: Int!, $queryText: String!, $option1: String!, $option2: String!, $option3: String!, $correctOption: Int!) {
	  	updateQuizQuery(id: $id, input: {queryText: $queryText, option1: $option1, option2: $option2, option3: $option3, correctOption: $correctOption }) {
		  success
	      message 
		  output {
			id

				key
			        queryText
			        option1
			        option2
					option3
					correctOption
		  }
		}
	 }
	`;

	let [searchParams] = useSearchParams();
	const queryKey = searchParams.get('query');
	const newQuery = queryKey === null;
	
	const path = "/orjan50/guests/"+guid+'/'+queryKey;
	
	const  { loading, error, data } = useQuery(QUERY_QUERY, {
			variables: { path: path },
//			fetchPolicy: "no-cache",
			onCompleted: data => {
				query = data.getQuizQuery;
				if (query) {
					queryId = parseInt(query.id);
					//setCorrectOption(query.correctOption);
				}
			}
		});
		
	const [callUpdateQuery, { error: gError }] = useMutation(UPDATE_QUERY_MUTATION, { 
		variables: { }, 
		onCompleted: data => {
			console.log(data.updateQuizQuery.output.id+' '+data.updateQuizQuery.message);	
			query = data.updateQuizQuery.output;		
			setSaving(false);			
		},
		onError: data => {
			console.log('Updating query failed');			
		}
	});
	
	if (newQuery) {
		query = {key: '',
			        queryText: '',
			        option1t: '',
			        option2t: '',
					option3t: '',
					correctOptiont: null}
	}

		
	if (loading) {
		console.log('loading query');
		query = null;
		return (
			<div>
				<ClipLoader loading={loading} size={150} />
			</div>
		);
	}
	if (error) {
		console.log('error loading query');
		return `Error! ${error}`;
	}
	if (gError) {
		console.log('error updating query');
		return `Error! ${gError}`;
	}
	if (!data || !data.getQuizQuery) {
		return (
			<div>
				Hittade ingen fråga med given nyckel.
			</div>
		);
	}
	
	if (!query && !saving) {
		query = data.getQuizQuery;
	}


	const onSave = (query) => {
		setSaving(true);
		callUpdateQuery({ variables : { id: queryId, queryText: query.queryText, option1: query.option1, option2: query.option2, option3: query.option3, correctOption: query.correctOption }});
	}
	
	if (saving) {
		return (
			<ClipLoader loading={saving} size={150} />
		);
	}


	return (
		<>
		    <BreadCrumbs path={[{link: "/", title:"Hem"}, {link: "/mypage", title:"Min sida"}, {link: "/querylist", title:"Quizlista"}]} title="Fråga" />
			<QuizQueryForm query={query} onSave={onSave} />
			
			<ClipLoader loading={saving} size={150} />
		</>
	);
}

export default QuizQuery;