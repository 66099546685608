import React, {useState} from 'react';
import { QrReader } from 'react-qr-reader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-regular-svg-icons'

function SignIn({setIsSignedIn, setPwd, token, pwd, server, setServer}) {

	const [displayQR, setDisplayQR] = useState(false);
	const [displayURLField, setDisplayURLField] = useState(false);
	
	const panic = () => {
		const doIt = window.confirm('Vill du verkligen rensa allt?');
		if (doIt) {
			localStorage.clear();
			window.location.href = 'signin';
		}	
	}
	
	
	const onInputChange = (event) => {
		const token = event.target.form[0].value;
		setIsSignedIn(token);
		
		const pwd = event.target.form[1].value;
		setPwd(pwd);
		
		const server = event.target.form[2].value;
		setServer(server);			
	}
	
	
	const urlLogin = (url) => {
    	const re = /https?:\/\/([^/]+\/)[^?]+\?apiKey=([a-z0-9]+)&guid=([a-z0-9]+)/i;
		const found = url.match(re);
		
		if (!found) {
			return;
		}
		
		const re2 = /https?:\/\/([^/]+\/)[^?]+\?apiKey=([a-z0-9]+)&guid=([a-z0-9]+)&server=(.*)/i;
		const found2 = url.match(re2);
		
		
		
		const defaultServer = 'https://www.glavichsandell.se/';

		setIsSignedIn(found[2]);		
		setPwd(found[3]);

				
		const s = unescape(found2[4]);
		setServer(found2 ? unescape(found2[4].trim()) : defaultServer);	
		
		window.location.href='/';		
	}
	
	const inviteUrl = window.location.href.replace('/signin', '/invite')+'?apiKey='+token+'&guid='+pwd+'&server='+escape(server);
	
	const qrReader = () => {
		if (!displayQR) {
			return null;
		}
		return (
			<QrReader
			constraints={{ facingMode: 'environment' }}
        onResult={(result, error) => {
	

          if (result && result !== "" ) {
			urlLogin(result.text);
          }

          if (error) {
            console.info(error);
          }
        }}
        style={{ width: '100%' }}
      />
		)
	}
	
	const urlField = () => {
		if (!displayURLField) {
			return null;
		}
		return (
			<>
			<br/>
			Klistra in länk: <input id="urlfield" size="45"  />
			<button onClick={() => {urlLogin(document.getElementById("urlfield").value)}}>Logga in</button>
			</>
		)
	}
	

	return (
		 <div>
			<br/>
	      {!displayURLField ? (<button onClick={() => {setDisplayQR(!displayQR)}}>{displayQR ? 'Stäng QR-läsaren' : 'Logga in med QR-kod'}</button>) : ''}
	 
			{qrReader()}
			
			{!displayQR ? (<button onClick={() => {setDisplayURLField(!displayURLField)}}>{displayURLField ? 'Göm Fält' : 'Logga in med inbjudningslänk'}</button>) : ''}
	 
			{urlField()}

			{ !displayURLField  && !displayQR ? (
				<>
				<br/>
<br/>
<br/>
<br/>
<h3>Ange nycklar manuellt:</h3>
            <form>
                <label>API-nyckel: </label>
                <input id="token" name="token" type="text" defaultValue={token ? token : ''} size="45"/><br/>
				<label>Personlig nyckel: </label>
                <input id="pwd" name="pwd" type="text" defaultValue={pwd ? pwd : ''} size="45"/><br/>
				<label>Server (url): </label>
                <input id="server" name="server" type="text" defaultValue={server ? server : ''} size="45"/><br/>
				<button type="button" onClick={onInputChange}>OK</button>
            </form>
<p/>
	<h3>Inbjudningslänk att kopiera till annan enhet:</h3> 
	{inviteUrl}
	    
         &nbsp;<button onClick={() => {if (!navigator.clipboard) {alert("Funktionen stöds ej"); return}; navigator.clipboard.writeText(inviteUrl)}}><FontAwesomeIcon icon={faCopy} /></button>


<h3>Övrigt</h3> 
<p>
<button onClick={() => {window.location.href='/'}}>Ladda om appen</button>
</p>
<p>
<button onClick={panic}>Panikrensa</button> Tömmer all lagrad data för denna app. Kräver inloggning på nytt.
</p>
</>
			) : ''}

        </div>
	);
}

export default SignIn;