import React from 'react';
import { Routes, Route } from 'react-router-dom';

import SignIn from './SignIn';
import MyPage from './MyPage';
import StartPage from './StartPage';
import QuizQueries from './Quiz/QuizQueries';
import QuizQuery from './Quiz/QuizQuery';
import NewQuizQuery from './Quiz/NewQuizQuery';
import GuestList from './GuestList/GuestList';
import Guest from './GuestList/Guest';
import Invite from './Invite';
import Version from './Version';
import PagedList from './ImageGallery/PagedList';
import WishList from './WishList/WishList';
import Quiz from './Quiz/Quiz';
import QuizTopList from './Quiz/QuizTopList';
import TableNumber from './TableNumber';


import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider
} from "@apollo/client";

const Main = ({setIsSignedIn, setPwd, token, guid, server, setServer, version, setVersion}) => {
	
  const client = new ApolloClient({
	  uri: server+'pimcore-graphql-webservices/orjan50?apikey='+token+'&guid='+guid,
	  cache: new InMemoryCache()
  });

  return (
	<div>
		<ApolloProvider client={client}>
		    <Version version={version} setVersion={setVersion} />
		    <Routes> {/* The Switch decides which component to show based on the current URL.*/}
			  <Route path='' element={<StartPage guid={guid} />} />
		      <Route path='signin' element={<SignIn setIsSignedIn={setIsSignedIn} token={token} setPwd={setPwd} pwd={guid} server={server} setServer={setServer}/>} />
			  <Route path='mypage' element={<MyPage guid={guid} server={server} />} />
			  <Route path='querylist' element={<QuizQueries guid={guid} />} />
		      <Route path='editquery' element={<QuizQuery guid={guid} />} />
			  <Route path='newquery' element={<NewQuizQuery guid={guid} />} />
			  <Route path='guestlist' element={<GuestList server={server} />} />
			  <Route path='guest/*' element={<Guest server={server} />} />
	          <Route path='gallery' element={<PagedList server={server} />} />
			  <Route path="table" element={<TableNumber guid={guid} />} />
			  <Route path='invite' element={<Invite setPwd={setPwd} setIsSignedIn={setIsSignedIn} setServer={setServer} />} />
			  <Route path='wishlist' element={<WishList />} />
			  <Route path='quiz' element={<Quiz guid={guid} />} />
			  <Route path='quiztoplist' element={<QuizTopList />} />
		    </Routes>
		</ApolloProvider>
	</div>
  );
}

export default Main;