import React, { useState }  from 'react';

import axios from 'axios';

import ClipLoader from "react-spinners/ClipLoader";


function FileUpload({ guid, server, type, fileUploaded, useTitle, useDescription}) {
	const [saving, setSaving] = useState(false);
	const [selectedFile, setSelectedFile] = useState(null);
	const [message, setMessage] = useState('');
	
	
	 // On file select (from the pop up) 
    const onFileChange = event => { 
      // Update the state 
      setSelectedFile(event.target.files[0]); 
    }; 
     
    // On file upload (click the upload button) 
    const onFileUpload = () => { 
		if (!selectedFile) {
			setMessage('Du måste välja en fil först.');
			return;
		}
      // Create an object of formData 
      const formData = new FormData(); 
     
      // Update the formData object 
      formData.append( 
        "theFile", 
        selectedFile, 
        selectedFile.name 
      ); 
	  formData.append("guid", guid); 
	  formData.append("type", type); 
	  if(useTitle)
	    formData.append("title", document.getElementById("fileUploadImageTitle").value);
      if(useDescription)
	    formData.append("description", document.getElementById("fileUploadImageDescription").value)
     
      // Details of the uploaded file 
      console.log(selectedFile); 
     
      // Request made to the backend api 
      // Send formData object 
      axios.post(server+"orjan50/upload?guid="+guid, formData)
		.then(response => {
			setMessage(response.data); 
			setSaving(false); 
			setSelectedFile(null);
			if (fileUploaded) {
				fileUploaded(response.data)
			}
		});
	  setSaving(true); 
    }; 
     
    // File content to be displayed after 
    // file upload is complete 
    const fileData = () => { 
      if (selectedFile) { 
          
        return ( 
          <div> 
            <h2>File Details:</h2> 
            <p>File Name: {selectedFile.name}</p> 
            <p>File Type: {selectedFile.type}</p> 
            <p> 
              Last Modified:{" "} 
              {selectedFile.lastModifiedDate.toDateString()} 
            </p> 
          </div> 
        ); 
      } else { 
        return ( 
          <div> 
            <br /> 
            <h4>Choose before Pressing the Upload button</h4> 
          </div> 
        ); 
      } 
    }; 
	if (saving) {
		return (
			<ClipLoader loading={saving} size={50} />
		);
	}
	const showMessage = () => {
		if (!message) {
			return null;
		}
		return (<div className="fileUploadMessage">{message}</div>);
	}
	const title = () => {
		if (!useTitle) {
			return null;
		}
		return (<><br/>Rubrik: <input typpe="text" name="imageTitle" id="fileUploadImageTitle"/></>);
	}
	const description = () => {
		if (!useDescription) {
			return null;
		}
		return (<><br/>Beskrivning: <textarea name="imageDescription" id="fileUploadImageDescription" cols="40" rows="5"></textarea></>);
	}
     
      return ( 
        <> 
			{showMessage()}
			<label for="file-upload" className="filebutton">Välj bild</label>
			    {selectedFile ? selectedFile.name : ''}
			
			    {title()}
				{description()}
                <input id="file-upload"  type="file" onChange={onFileChange} /> <br/>
                <button onClick={onFileUpload}>Skicka bild</button> 
        </> 
      ); 
}

export default FileUpload;