import React, { useState }  from 'react';

import {
	gql,
	useMutation
} from "@apollo/client";

import ClipLoader from "react-spinners/ClipLoader";
import { useSearchParams } from "react-router-dom";

import QuizQueryForm from './QuizQueryForm';
import { useNavigate } from "react-router-dom";

let query;

function NewQuizQuery({ guid }) {

	const [saving, setSaving] = useState(false);

	const CREATE_QUERY_MUTATION = gql`
	 mutation createQuizQuery($key: String!, $path: String!, $queryText: String!, $option1: String!, $option2: String!, $option3: String!, $correctOption: Int!) {
	  createQuizQuery(key: $key, published: true, path: $path, input: {queryText: $queryText, option1: $option1, option2: $option2, option3: $option3, correctOption: $correctOption}) {
	    success
	    message
	    output {
	     	id
		 	key
        	queryText
        	option1
        	option2
			option3
			correctOption
	    }
	  }
	}
  `;


	
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const queryKey = searchParams.get('query');
	const nextPage = searchParams.get('source') || '/querylist';
	
	const path = "/orjan50/guests/"+guid;
	
		
	const [callCreateQuery, { error }] = useMutation(CREATE_QUERY_MUTATION, { 
		variables: { path: path, key: queryKey}, 
		onCompleted: data => {	
			query = data.createQuizQuery.output;
			navigate( nextPage+'?added='+query.key);
		},
		onError: data => {
			console.log('Creating query failed');			
		}
	});
	
	if (query && query.id) {
		
	}
	

	query = {
	         queryText: '',
	         option1t: '',
	         option2t: '',
			 option3t: '',
			 correctOptiont: null
		};

		
	if (error) {
		return `Error! ${error}`;
	}


	const onSave = (query) => {
		setSaving(true);
		callCreateQuery({ variables : { queryText: query.queryText, option1: query.option1, option2: query.option2, option3: query.option3, correctOption: query.correctOption }});
	}
	
	if (saving) {
		return (
			<ClipLoader loading={saving} size={150} />
		);
	}


	return (
		<>
			<QuizQueryForm query={query} onSave={onSave} />
			
			<ClipLoader loading={saving} size={150} />
		</>
	);
}

export default NewQuizQuery;