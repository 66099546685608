import React,{ useState }  from 'react';
import Main from './Main';
import { Link } from "react-router-dom";
import SignIn from './SignIn';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBarChart, } from '@fortawesome/free-regular-svg-icons'
import './App.css';

const API_TOKEN = 'apiToken'; 
const PWD = 'pwd';
const SERVER = 'server';


 
function App() {
	/* Toggle between showing and hiding the navigation menu links when the user clicks on the hamburger menu / bar icon */
		const toggleMenu = () => {
		var x = document.getElementById("myLinks");
		  if (x.style.display === "block") {
		    x.style.display = "none";
		  } else {
		    x.style.display = "block";
		  }
	}

 	const token = localStorage.getItem(API_TOKEN);
	const pwd = localStorage.getItem(PWD);
	const server = localStorage.getItem(SERVER);
	const [isSignedIn, setIsSignedIn] = useState(token);
	const [pwdPath, setPwdPath] = useState(pwd);
	const [serverUrl, setServerUrl] = useState(!server || server.includes('aws.glavichsandell.se') ? 'https://www.glavichsandell.se/' : server);
	const [version, setVersion] = useState(localStorage.getItem('app_version'));
	
	const login = (token) => {
		localStorage.setItem(API_TOKEN, token);
		setIsSignedIn(token);
	}
	
	const logout = () => {
		const doIt = window.confirm("Om du gör detta kommer du behöva en inbjudningslänk, eller kunna fylla i api-nyckeln och personlig nyckel, för att kunna fortsätta använda appen.");
		if (doIt) {
			localStorage.removeItem(API_TOKEN);
			localStorage.removeItem(PWD);
			setIsSignedIn(0);
			setPwdPath('');
		}
		
		toggleMenu();
		return doIt;
	}
	
	const setPwd = (pwd) => {
		localStorage.setItem(PWD, pwd);
		setPwdPath(pwd);
	}
	
	const setServer = (server) => {
		localStorage.setItem(SERVER, server);
		setServerUrl(server);
	}
	
	const toInvite = window.location.pathname === '/invite';

    return (    
       <div className="App">

<div className ="topnav">
    <span className="menuicon" onClick={toggleMenu}><FontAwesomeIcon className="nobreak" icon={faBarChart} /></span><span className="titlelogocontainer" ><Link className="nobreak" to="/"><img className="titlelogo" src="/logo192.png" width="35" height="35" alt="Ö50"/></Link></span>
    <Link className="mypage_in_hereader" to="/mypage" >🧑</Link>
    
	
	  <div id="myLinks">
		<Link onClick={toggleMenu} to="/">Hem</Link>
		<Link onClick={toggleMenu} to="/mypage">Min sida</Link>
		<Link onClick={toggleMenu} to="/querylist">Mina quiz-frågor</Link>
		<Link onClick={toggleMenu} to="guestlist">Gästlista</Link>
		<Link onClick={toggleMenu} to="gallery">Bildgalleri</Link>
		<Link onClick={toggleMenu} to="wishlist">Presentförslag</Link>
		<Link onClick={toggleMenu} to="/quiz">Quiz</Link>
		<Link onClick={toggleMenu} to="/quiztoplist">Resultatlista Quiz</Link>
		<Link onClick={toggleMenu} to="/table">Ditt bord?</Link>
		<Link onClick={toggleMenu} to="/signin">Inställningar</Link>		
		<Link onClick={logout} to="/">Logga ut</Link>
	  </div>
  
</div>
<span className="version">{version}</span>
<a href="/" className="reload">🔄</a>


			{isSignedIn || toInvite ? <Main setPwd={setPwd} setIsSignedIn={login} token={token} guid={pwdPath} server={serverUrl} setServer={setServer}  version={version} setVersion={setVersion}/> : <SignIn setIsSignedIn={login} setPwd={setPwd}  token={token} pwd={pwd} server={server} setServer={setServer}/>}
       </div>
 
    );
  }

export default App;    
