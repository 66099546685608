import React  from 'react';


function MyPageTexts({ guest }) {

	return (
		
		<div>
		   
				<p>
				Skriv vem du är och vilken relation du har till jubilaren. Texten kommer att användas för att presentera dig för övriga gäster:<br/>
					<textarea name="description" cols="40" rows="5" defaultValue={guest.description}></textarea>
				</p>
				
				<p>
				Här kan du skriva en hälsning till jubilaren. Texten kommer att kunna läsas av övriga gäster:<br/>
					<textarea name="publicGreeting" cols="40" rows="5" defaultValue={guest.publicGreeting}></textarea>
				</p>
				
				<p>
				Här kan du skriva en personlig hälsning till jubilaren. Texten kommer inte att kunna läsas av övriga gäster:<br/>
					<textarea name="privateGreeting" cols="40" rows="5" defaultValue={guest.privateGreeting}></textarea>
				</p>
				
				<p>
				Här kan du skriva om du har några särskilda önskemål, t.ex. speciell kost:<br/>
					<textarea name="message" cols="40" rows="5" defaultValue={guest.message}></textarea>
				</p>
				
				
				
		</div>
	);
}

export default MyPageTexts;