import React   from 'react';

import {
	useQuery,
	gql
} from "@apollo/client";

import ClipLoader from "react-spinners/ClipLoader";
import FileUpload from '../FileUpload';
import ListImage from '../GuestList/ListImage';
import axios from 'axios';


function ImageGallery({ guid, server }) {

	//const [saving, setSaving] = useState(false);
	//const [attending, setAttending] = useState();
	
	let images = [];
	const path = "/orjan50/guests/"+guid;

	const GALLERY_QUERY = gql`
		     query getOrjan50Guest($path: String!)  {
			  getOrjan50Guest(fullpath: $path) {
				id
			   imageGallery {
			      ... on asset {
			        id
			        fullpath
					metadata {
			          name
			          data
			        }
			      }
			    }
			  }
			}
`;

	const { loading, error, data, refetch: refetchImages } = useQuery(GALLERY_QUERY, {
		variables: { path:  path},
		onCompleted: data => {

		}
	});
	


	if (loading) {
		console.log('loading images');
		return (
			<div>
				<ClipLoader loading={loading} size={150} />
			</div>
		);
	}
	if (error) {
		console.log('error loading guest');
		return `Error! ${error}`;
	}
	if (data) {
		const gallery = data.getOrjan50Guest.imageGallery || [];
		images = gallery.map(image => {return {id: image.id, fullpath: image.fullpath, metadata: image.metadata };});
	}
	
	const fileUploaded = () => {
		refetchImages();
	}
	
	const removeImage = (id) => {
		const doIt = window.confirm("Vill du verkligen ta bort bilden?");
		if (doIt) {
			axios.get(server+"orjan50/remove?guid="+guid+"&id="+id)
			.then(response => {
				refetchImages();
			});
		}
	}
	
	
	return (
		<>
		<h2>Bildgalleri</h2>
		Ladda gärna upp bilder med koppling till jubilaren. Kommer visas för alla gäster.<br/>
		<FileUpload  guid={guid} server={server} type="gallery" fileUploaded={fileUploaded} useTitle="1" useDescription="1"/>
				
		{images.map(image => (<p key={image.id}>
			<span className="remove" onClick={()=> removeImage(image.id)}>❌</span> {image.id}&nbsp;
			<ListImage image={image} thumbnail="orjan50listthumb2" server={server}/>
			
		</p>))}
		
		</>
	);

}

export default ImageGallery;
