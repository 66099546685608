import React from 'react';

import { Link } from "react-router-dom";


function BreadCrumbs({path, title}) {

	return (
		<div className="breadcrumbs">
			{path.map(page => (
				<span key={page.title} ><Link to={page.link}>{page.title}</Link>&nbsp;&gt;&nbsp;</span>
			))}
			 {title}
		</div>
	);

}

export default BreadCrumbs;