import React, {useState, useEffect}  from 'react';

import {
	useLazyQuery,
	gql
} from "@apollo/client";

import ClipLoader from "react-spinners/ClipLoader";
import { Link } from "react-router-dom";
import ListImage from "./ListImage";
import { useNavigate } from "react-router-dom";
import BreadCrumbs from "../BreadCrumbs";

const descriptionLength = 240;
let modefiedSince = 0;
let guests = [];

function GuestList({server}) {

	const [state, setState] = useState([]);
	const navigate = useNavigate();
	

	const GUESTS_QUERY = gql`
		     query getOrjan50GuestListing($filter: String!)  {
			  getOrjan50GuestListing(filter: $filter) {
			    edges {
			      node {
			        id
				    name
					attending
					description
					publicGreeting
					extraGuest1
					extraGuest2
					extraGuest3
					extraGuest4
					modificationDate
					portrait {
						fullpath
						id
					}
					extraGuest1Portrait {
					fullpath
					id
				}
				extraGuest2Portrait {
					fullpath
					id
				}
				extraGuest3Portrait {
					fullpath
					id
				}
				extraGuest4Portrait {
					fullpath
					id
				}
			      }
			    }
			  }
			}
`;

	const path = '/orjan50/guests';
	const filter  = '{"o_path": {"$like" : "'+path+'/%"}, "attending": {"$like" : true}, "o_modificationDate": {"$gt" :"'+modefiedSince+'"}}';
	const [getGuests, { loading, error, data, refetch }] = useLazyQuery(GUESTS_QUERY, {
		variables: { filter:  filter},
		onCompleted: data => {
			console.log('done fetching GuestList');
			setState(new Date());
		}
	});
	
	useEffect(() => {
		console.log('enter GuestList');
		if (!loading) {
			console.log('get GuestList');
			 refetch();
		}
	   
	}, []);
	

/*
	if (loading) {
		console.log('loading guests');
		return (
			<div>
				<ClipLoader loading={loading} size={150} />
			</div>
		);
	}
	*/
	if (error) {
		console.log('error loading guest');
		return `Error! ${error}`;
	}
	if (data) {
		const fetchedGuests = data.getOrjan50GuestListing.edges.map(query => {return {id: query.node.id, name: query.node.name, description: query.node.description, 
										publicGreeting: query.node.publicGreeting,
										extraGuest1: query.node.extraGuest1, extraGuest2: query.node.extraGuest2, extraGuest3: query.node.extraGuest3, extraGuest4: query.node.extraGuest4, modificationDate: query.node.modificationDate, portrait: query.node.portrait, 
										extraGuest1Portrait: query.node.extraGuest1Portrait, extraGuest2Portrait: query.node.extraGuest2Portrait, extraGuest3Portrait: query.node.extraGuest3Portrait, extraGuest4Portrait: query.node.extraGuest4Portrait };});
		
		const newGuests = [];
		guests.forEach(guest => {
			if (!fetchedGuests.find(g => {return g.id === guest.id})) {
				newGuests.push(guest);
			}
		});
		newGuests.push(...fetchedGuests);							
										
		guests = newGuests.sort((a, b) => {
			const aNames = a.name.split(' ');
			const bNames = b.name.split(' ');
			const res = aNames[aNames.length-1].localeCompare(bNames[bNames.length-1]);
			return res !== 0 ? res : a.name.localeCompare(b.name);
		}, guests);
		
		
		
		
		guests.forEach(guest => {
			modefiedSince = guest.modificationDate > modefiedSince ? guest.modificationDate : modefiedSince;
		});
	}
	
	const extraGuest = (name) => {
		if (!name) {
			return null;
		}
		return (
			<>
				, {name}	
			</>							
		);
	}	
	
	
	const rowClicked = (id) => {
  		navigate('/guest/'+id);		
	}
	
	const whileLoading = () => {
		return (
			<div className="spinnerpopup">
				<ClipLoader className="popuptext" loading={loading} size={50} />
			</div>
		);
	}	
	
	

	return (
		<>
		<BreadCrumbs path={[{link: "/", title:"Hem"}]} title="Gästlista" />
		Följande gäster har tackat ja till inbjudan. {whileLoading()}
		<table  className="guestlisttable">
				{guests.map(guest => (<tbody className="guestlistrow" onClick={() => {rowClicked(guest.id)}} key={guest.id}><tr  className="guestlist" >  
												<td valign="top"><span className="guestListName">{guest.name}</span>{extraGuest(guest.extraGuest1)}{extraGuest(guest.extraGuest2)}{extraGuest(guest.extraGuest3)}{extraGuest(guest.extraGuest4)}</td> 
												<td><ListImage image={guest.portrait} thumbnail="orjan50listthumb2" server={server}/>
												<ListImage image={guest.extraGuest1Portrait} thumbnail="orjan50listthumb2" server={server}/>
												<ListImage image={guest.extraGuest2Portrait} thumbnail="orjan50listthumb2" server={server}/>
												<ListImage image={guest.extraGuest3Portrait} thumbnail="orjan50listthumb2" server={server}/>
												<ListImage image={guest.extraGuest4Portrait} thumbnail="orjan50listthumb2" server={server}/></td>
												
												<td><Link to={'/guest/'+guest.id}>👀</Link></td>									
											</tr>
											<tr >  
												<td colSpan="2">{!guest.description || guest.description.length <= descriptionLength ? guest.description : guest.description.substring(0, descriptionLength) + '...'}</td>	
																					
											</tr></tbody>											
									)

				)}
		</table>
		</>
	);

}

export default GuestList;
