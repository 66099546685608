import React, { useState }  from 'react';


function QuizQueryForm({ query, onSave }) {

	const [correctOption, setCorrectOption] = useState(query.correctOption);
	const [errorMsg, setErrorMsg] = useState('');
	
	const handleOptionChange = event => {
			setCorrectOption(event.target.value);
	  };


	const onSaveForm = (event) => {		
		const queryText = event.target.form[0].value;
		const option1 = event.target.form[1].value;
		const option2 = event.target.form[2].value;
		const option3 = event.target.form[3].value;
		
		let error = '';
		
		if (!queryText) {
			error += " Fråga saknas.";
		}
		if (!option1) {
			error += " Svarsalternativ 1 saknas.";
		}
		if (!option2) {
			error += " Svarsalternativ X saknas.";
		}
		if (!option3) {
			error += " Svarsalternativ 2 saknas.";
		}
		if (!correctOption) {
			error += " Rätt svar saknas.";
		}
		if (!error) {
			onSave( { queryText: queryText, option1: option1, option2: option2, option3: option3, correctOption: parseInt(correctOption) });
		}
		setErrorMsg(error);
	}

	return (
		
		<div className="quizquery">
		   <span className="errormessage">{errorMsg}</span>
			<form>
				<p>
				Fråga:<br/>
					<textarea name="queryText" cols="40" rows="5" defaultValue={query.queryText}></textarea>
				</p>
				
				<p>
				Svarsalternativ 1:<br/>
					<textarea name="option1" cols="40" rows="5" defaultValue={query.option1}></textarea>
				</p>
				
				<p>
				Svarsalternativ X:<br/>
					<textarea name="option2" cols="40" rows="5" defaultValue={query.option2}></textarea>
				</p>
				
				<p>
				Svarsalternativ 2:<br/>
					<textarea name="option3" cols="40" rows="5" defaultValue={query.option3}></textarea>
				</p>
				
				<p>
				 Rätt svar:<br/>
					<label htmlFor="attendingyes">1</label> <input id="correctOption1" type="radio" value="1" name="correctOption" checked={correctOption == '1'}   onChange={handleOptionChange}/><br/> 
			        <label htmlFor="attendingno">X</label> <input id="correctOption2" type="radio" value="2" name="correctOption"  checked={correctOption == '2'}   onChange={handleOptionChange}/><br/> 
					<label htmlFor="attendingno">2</label> <input id="correctOption3" type="radio" value="3" name="correctOption"  checked={correctOption == '3'}   onChange={handleOptionChange}/><br/>  
				</p>
				
				<button type="button" name="saveQuery" onClick={onSaveForm}>Spara</button>
			</form>
		</div>
	);
}

export default QuizQueryForm;