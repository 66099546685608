import React, {useState, useEffect}   from 'react';

import {
	useLazyQuery,
	gql
} from "@apollo/client";

import ClipLoader from "react-spinners/ClipLoader";
import { Link } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import BreadCrumbs from "../BreadCrumbs";

let resultList = [];

const RESPONSE_QUERY = gql`
query getQuizResponseListing($filter: String!)  {
  getQuizResponseListing(filter: $filter) {
    edges {
      node {
        id
        selectedOption
        isCorrect
        query {
          ... on object_QuizQuery {
            id
          }
        }
        parent {
          ... on object_Orjan50Guest {
            id
            name
          }
        }
      }
    }
  }
}
`;
// filter: "{\"o_path\": {\"$like\" : \"/orjan50/guests/%\"}}"
function QuizTopList() {

	const [progress, setProgress] = useState(0);


	const filter  = '{"o_path": {"$like" : "/orjan50/guests/%"}}';
	const [getResponses, { loading, error, data, refetch }] = useLazyQuery(RESPONSE_QUERY, {
		variables: { filter:  filter},
		onCompleted: data => {
			setProgress(Date.now());
		}
	});
	
	useEffect(() => {
		if (!loading) {
			 refetch();
		}
	   
	}, []);
	
	
	


	if (loading) {
		return (
			<div>
				<ClipLoader loading={loading} size={150} />
			</div>
		);
	}
	if (error) {
		console.log('error loading guest');
		return `Error! ${error}`;
	}
	if (data) {
		resultList = [];
		const responses = data.getQuizResponseListing.edges.map(resp => {return {id: resp.node.id, selectedOption: resp.node.selectedOption, isCorrect: resp.node.isCorrect, queryId: resp.node.query.id, 
			guestId: resp.node.parent.id, name: resp.node.parent.name };});
		const results = {};
		
		responses.forEach(r => {
			const result = results.hasOwnProperty(r.guestId) ? results[r.guestId] : {id: r.guestId, name: r.name, score: 0, count: 0};
			if (r.isCorrect) {
				result.score++;
			}
			result.count++;
			results[r.guestId] = result;
		});
		
		
		for (const r in results) {
		  resultList.push(results[r]);
		}
		resultList.sort((a,b) => b.score - a.score);
	}
	
	

	return (
		<>
		<BreadCrumbs path={[{link: "/", title:"Hem"}]} title="Quiz-resultat" />
         <button onClick={() => {refetch()}}>↻</button><br/>
		<table className="quizresultlist">
			<tbody>
				{resultList.map(res => ( <tr className="resultdisplayrow" key={res.guestId}>  
												<td >{res.name}</td>
												<td align="right">{res.score}/{res.count}</td>											
											</tr>))}
			</tbody>
		</table>
		</>
	);

}

export default QuizTopList;
