import React from 'react';
import GuestName from './GuestName';

function StartPage({guid}) {
	
	

	
	const guestName = () => {
		if (localStorage.getItem('guest_name')) {
			return (localStorage.getItem('guest_name') );
		}	
		return (<GuestName guid={guid} />);
	}

	return (
		
		<div>
		
		<h1 className="center">Välkommen <br/>{ guestName() }</h1>
			Den <span className="osaDate">13 augusti</span> händer det. Örjan planerar att fira sin 50-årsdag 
			och det vore roligt om du ville vara med och fira. I denna web-app, som du nu är inne på, kan du ta del av och lägga in information.
			
			
			<p>
			På eftermiddagen, runt <strong>14:00</strong>, samlas vi i <a href="https://www.google.se/maps/place/58%C2%B031'03.6%22N+15%C2%B031'17.1%22E/@58.517658,15.5192163,17z/data=!3m1!4b1!4m6!3m5!1s0x0:0xb809463b60d3220d!7e2!8m2!3d58.5176582!4d15.5214046?hl=sv" target="_blank">Olstorp</a> för 
			lite utomhusaktiviteter om vädret tillåter. Det kommer finnas lite olika saker att göra. 
			Exakt vad, är inte planerat ännu, men förbered dig på att det kan innebära viss rörelse, men inget fysiskt krävande.
			Vi kommer ha tillgång till OK Skogsströvarnas klubbstuga. Vreta SoMK:s omklädningsrum med dusch kommer också vara tillgängligt
			vid behov. 					
			</p>
			<p>
			Vi avslutar Olstorp-aktiviteterna någon gång runt <strong>16-16:30</strong>. Därefter får man på eget bevåg ta sig 
			till <a href="https://www.google.se/maps/place/Cloetta+Sverige+AB/@58.5128994,15.4972749,17z/data=!3m1!4b1!4m14!1m8!3m7!1s0x0:0xb809463b60d3220d!2zNTjCsDMxJzAzLjYiTiAxNcKwMzEnMTcuMSJF!3b1!7e2!8m2!3d58.5176582!4d15.5214046!3m4!1s0x46593a3b82bf0cd7:0x2c73597470f3c8d9!8m2!3d58.5128994!4d15.4994636?hl=sv" target="_blank">Restaurang tre bröder</a>, ca.
			två km från Olstorp.
			Där startar vi runt kl. <strong>18:00</strong> med mingel, bubbel och snittar.
			</p>
			<p>
			Vid <strong>19:00</strong> blir det Buffé. Senare på kvällen blir det kaffe och tårta. Till maten bjuds på dryck i form av öl, vin, cider, 
			både med och utan alkohol, samt läsk. Det kommer även finnas en bar, om man önskar köpa mer dryck senare. 
			Mer information om kvällen kommer läggas in på denna sida senare.
			Ta med dig din telefon och var inloggad i denna web-app. Det kommer vara till nytta under kvällen. 
			</p>
			
			<p className="start_page_paragraph">
				<h2 className="start_page_h2">OSA och personlig info</h2>
				På "<a href="/mypage">Min sida</a>", som du når via länken här, i menyn uppe till vänster, eller emojin uppe till höger,
				kan du ange om du har möjlighet att komma. Där kan du också skicka hälsning, lägga in bild på dig själv och bidra med quiz-frågor 
				och bilder, för att medverka till att förhöja utbytet för alla gäster.
				
				Ange också om du har några speciella önskemål, t.ex. när det gäller mat. OSA och speciella önskemål gällande mat vill jag ha
				in senast  <span className="osaDate">30 juni</span>. Glöm inte att klicka "Spara" när du gjort en ändring.
				Får du problem att anmäla dig via denna web-app, så hör av dig till mig
				på <a href="mailto:orjan@glavichsandell.se">orjan@glavichsandell.se</a>.
			</p>
			
			<p className="start_page_paragraph">
				<h2 className="start_page_h2">Toastmadame</h2>
				Maria Lillieström kommer vara toastmadame på festen. Kontakta henne om du vill framföra, eller hitta på något speciellt under festen.
				Maria nås enklast på e-post <a href="mailto:maria@lilliestrom.nu">maria@lilliestrom.nu</a>, eller telefon 073-046 09 98.
			</p>
			
			<p className="start_page_paragraph">
				<h2 className="start_page_h2">Gästlista</h2>
				I <a href="/guestlist">Gästlistan</a> listas alla gäster som tackat ja till inbjudan. Extra roligt om man skrivit några rader
				om sig själv, som då alla kan ta del av. De bilder som laddas upp kommer också att bli tillgängliga i ett bildgalleri så småningom,
				och kommer även visas under kvällen.
			</p>
			
			
			
			<p className="food_menu">
				<h2>Mingel</h2>
				<span className="food_menu_row">Ostterrin Royal med löjrom och fint hackad gräslök</span><br/>

				<span className="food_menu_row">Gravlaxtartar serveras med senapskräm och örter </span><br/>

				<span className="food_menu_row">Svampmousse med ingefärspicklad kantarell (veg)  </span><br/>

			</p>
			
			<p className="food_menu">
			<h2>Buffé</h2>
			<span className="food_menu_row">Potatissallad med kapris, dijonsenap och örter </span><br/>
<span className="food_menu_row">Tryffel- och honungsmarinerade morötter med persilja och långlagrad cheddarost</span><br/>
</p>
			<p className="food_menu">
<span className="food_menu_row">Tomat- och rödlöksallad </span><br/>
<span className="food_menu_row">Rostad blomkål med äpple och pepparrot </span><br/>
<span className="food_menu_row">Salladsblad och skott</span><br/>
<span className="food_menu_row">Ruccolacrème med citron och vitlök</span><br/>
<span className="food_menu_row">Bröd och smör</span><br/>
</p>
			<p className="food_menu">
<span className="food_menu_row">Ugnsbakad laxfilé med sticklök och citron</span><br/>
<span className="food_menu_row">Citron- och timjanstekt gårdskyckling</span><br/>
<span className="food_menu_row">Fläskfilé med syrad lök</span><br/>

			</p>


       		
		</div>
	);
}

export default StartPage;