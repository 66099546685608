import React, {useState}   from 'react';

import {
	useQuery,
	useLazyQuery,
	gql,
} from "@apollo/client";

import ClipLoader from "react-spinners/ClipLoader";


const GUEST_NAME = 'guest_name';


function GuestName({guid}) {

	const [guestName, setGuestName] = useState(localStorage.getItem(GUEST_NAME));
	
	
	const GUEST_QUERY = gql`
		     query getOrjan50Guest($fullpath: String!)  {
			  getOrjan50Guest(fullpath: $fullpath) {
				id
			    name
				extraGuest1
				extraGuest2
				extraGuest3
				extraGuest4
			  }
			}
		    `;
	
	const extraName = (guest, no) => {
		if (!guest['extraGuest'+no]) {
			return '';
		}
		return  ', '+ guest['extraGuest'+no];
	}
	
	const { loading: loading, data: data } = useQuery(GUEST_QUERY, {
			variables: { fullpath: "/orjan50/guests/"+guid },
			onCompleted: data => {
				if (data.getOrjan50Guest.name) {
					const guest = data.getOrjan50Guest;
					const value = guest.name + extraName(guest, 1) + extraName(guest, 2) + extraName(guest, 3) + extraName(guest, 4);
					localStorage.setItem(GUEST_NAME, value);
					setGuestName(value);
				}
				
			}
	});
	
	if (loading) {
		return null;
	}

	
	
	return (
		<span>{guestName || ''}</span>
	);

}

export default GuestName;